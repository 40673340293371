import React, { useEffect, useState } from "react";
import "./TeacherList.scss";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { get } from "../../redux/ApiHelper";
import { TeacherInterface } from "../../interfaces/teacher.interface";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: "var(--color-primary)",
    padding: 4,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 4,
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TeacherList = () => {
  const [teacherList, setTeacherList] = useState<TeacherInterface[]>([]);

  useEffect(() => {
    get("/teacher/get-all").then((res) => {
      setTeacherList(res);
    });
  }, []);

  return (
    <div className="teacher">
      <div className="title">Teacher List</div>
      <div className="container">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Profile Picture</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Phone Number</StyledTableCell>
                <StyledTableCell>Address</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teacherList.map((teacher) => (
                <StyledTableRow key={teacher._id}>
                  <StyledTableCell>
                    <img
                      src={teacher?.profilePicture}
                      alt={teacher?.name}
                      className="profile-img"
                    />
                  </StyledTableCell>
                  <StyledTableCell>{teacher.name}</StyledTableCell>
                  <StyledTableCell>{teacher.phoneNumber}</StyledTableCell>
                  <StyledTableCell>
                    {teacher.address
                      ? `${teacher.address.addressLine}, ${teacher.address.city}, ${teacher.address.state}, ${teacher.address.pinCode}`
                      : "N/A"}
                  </StyledTableCell>
                  <StyledTableCell>{teacher.email}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default TeacherList;
