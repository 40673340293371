import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../components/table/TableStyle";
import { TuitionRequestInterface } from "../../../interfaces/tuitionRequest.interface";
import { useDispatch, useSelector } from "react-redux";
import { DispatchType } from "../../../interfaces/redux.interface";
import { changeRequestStatus } from "../../../redux/slices/TuitionRequestSlice";
import { startLoading, stopLoading } from "../../../redux/slices/WindowSlice";

const TeacherSpecificRequest = () => {
  const dispatch: DispatchType = useDispatch();
  const {
    specificTuitionRequests,
  }: { specificTuitionRequests: TuitionRequestInterface[] } = useSelector(
    (state: any) => state.tuitionRequest
  );

  const handleStatusChange = async (id: string, newStatus: string) => {
    dispatch(startLoading());
    await dispatch(changeRequestStatus({ id, status: newStatus })).finally(
      () => {
        dispatch(stopLoading());
      }
    );
  };
  return (
    <Table sx={{ minWidth: 700 }} aria-label="specific requests table">
      <TableHead>
        <TableRow>
          <StyledTableCell>Email</StyledTableCell>
          <StyledTableCell>Phone Number</StyledTableCell>
          <StyledTableCell>Preferred Days</StyledTableCell>
          <StyledTableCell>Preferred Subjects</StyledTableCell>
          <StyledTableCell>Status</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {specificTuitionRequests?.map((card, index) => (
          <StyledTableRow key={index}>
            <StyledTableCell>{card.email}</StyledTableCell>
            <StyledTableCell>{card.phoneNumber}</StyledTableCell>
            <StyledTableCell>
              {card.preferredDaysOfWeek.join(", ")}
            </StyledTableCell>
            <StyledTableCell>
              {card.preferredSubjects.join(", ")}
            </StyledTableCell>
            <StyledTableCell>
              <select
                value={card.status}
                onChange={(e) => {
                  handleStatusChange(card?._id, e.target.value);
                }}
                style={{
                  padding: "4px",
                  fontSize: "14px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              >
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TeacherSpecificRequest;
