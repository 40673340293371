// Sidebar menu structure
export const SidebarMenu = [
  {
    menu: "Dashboard",
    path: "/",
  },
  {
    menu: "Teacher",
    subMenu: [
      {
  menu: "Teacher List",
  path: "/teacher",
},
]
  },
  {
    menu: "Student",
    subMenu: [
            {
        menu: "Student List",
        path: "/student",
      },
    ]
  },
    {
    menu: "Tuition Periods",
    path: "/tuition-period",
  },
  {
    menu: "Request",
    path: "/request",
  }
  // {
  //   menu: "Admin",
  //   subMenu: [
  //     {
  //       menu: "Create",
  //       path: "/admin/create",
  //     },
  //     {
  //       menu: "List",
  //       path: "/admin/list",
  //     },
  //   ],
  // },
  // {
  //   menu: "Teacher",
  //   subMenu: [
  //     {
  //       menu: "List",
  //       path: "/teacher/list",
  //     },
  //     {
  //       menu: "Intro Question",
  //       path: "/teacher/intro-questions",
  //     },
  //     {
  //       menu: "Subject Certification",
  //       path: "/teacher/subject-certification",
  //     },
  //   ],
  // },
  // {
  //   menu: "Subjects",
  //   subMenu: [
  //     {
  //       menu: "List",
  //       path: "/subjects/list",
  //     },
  //     {
  //       menu: "Teacher Certification Questions",
  //       path: "/subjects/teacher-certification-questions",
  //     },
  //     {
  //       menu: "Subject Certification",
  //       path: "/subjects/subject-certification",
  //     },
  //   ],
  // },
];
