// src/App.tsx
import React, { useEffect } from "react";
import "./App.css";
import "./global.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Login from "./pages/login/Login";
import NotFound from "./pages/notFound/NotFound";

import { useNavigate } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import AdminList from "./pages/admin/AdminList";
import Teacher from "./pages/teacher/TeacherList";
import Student from "./pages/student/StudentList";
import Request from "./pages/request/Request";
import TuitionPeriod from "./pages/tuition-period/TuitionPeriod";

const useAuthCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    if (!token) {
      navigate("/login");
    } else {
      // You can add token validation or refresh logic here if necessary
    }
  }, [navigate]); // Trigger this effect whenever the component mounts
};

const App: React.FC = () => {
  useAuthCheck(); // Custom hook to check authentication and redirect if necessary
  return (
    // <Router>
    <div className="App">
      <Routes>
        {/* All routes will be wrapped with Layout */}
        <Route element={<Layout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/teacher" element={<Teacher />} />
          <Route path="/student" element={<Student />} />
          <Route path="/request" element={<Request />} />
          <Route path="/tuition-period" element={<TuitionPeriod />} />
          {/* <Route path="/admin/list" element={<AdminList />} /> */}
          {/* <Route path="/about" element={<About />} /> */}
        </Route>

        {/* Routes without layout */}
        <Route path="/login" element={<Login />} />

        {/* Catch-all Route for 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
    // </Router>
  );
};

export default App;
