import React, { useState } from "react";
import { Typography, TextField, Button, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import { DispatchType } from "../../interfaces/redux.interface";
import { useDispatch } from "react-redux";
import { post } from "../../redux/ApiHelper";
import { startLoading, stopLoading } from "../../redux/slices/WindowSlice";

const labelColor = "#F9D402";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: DispatchType = useDispatch();

  // State for email, password, and error message
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleLogin = async () => {
    setError(null); // Reset error message
    dispatch(startLoading());

    await post("/admin/auth/super-admin-login", { email, password })
      .then((response) => {
        localStorage.setItem("jwt", response.token);
        navigate("/");
      })
      .catch((err) => {
        setError(err.message || "Login failed. Please try again.");
      })
      .finally(() => {
        dispatch(stopLoading());
      });
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <img className="logo" src="./logo.svg" alt="" />
        <Typography variant="h5" gutterBottom>
          Login
        </Typography>

        {error && <Alert severity="error">{error}</Alert>}

        <TextField
          className="form-field"
          label="Email"
          variant="outlined"
          margin="normal"
          value={email}
          sx={{
            "& .MuiInputBase-root": { backgroundColor: "transparent !important" },
            "& .MuiInputLabel-root": { color: labelColor },
            "& .MuiInputLabel-root.Mui-focused": { color: labelColor }
          }}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          className="form-field"
          label="Password"
          type="password"
          variant="outlined"
          margin="normal"
          value={password}
          sx={{
            "& .MuiInputBase-root": { backgroundColor: "transparent !important" },
            "& .MuiInputLabel-root": { color: labelColor, backgroundColor: "transparent" },
            "& .MuiInputLabel-root.Mui-focused": { color: labelColor }
          }}
          onChange={(e) => setPassword(e.target.value)}
        />

        <Button
          variant="contained"
          className="login-btn"
          onClick={handleLogin}
          disabled={!email || !password} // Disable button if fields are empty
        >
          Login
        </Button>
      </div>
    </div>
  );
};

export default Login;
