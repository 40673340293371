import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TeacherInterface } from "../../interfaces/teacher.interface";
import { get } from "../ApiHelper";

interface TeacherState {
  status: string;
  allTeachers: TeacherInterface[];
}

const initialState: TeacherState = {
  status: "idle",
  allTeachers: [],
};

export const getTeachers = createAsyncThunk("get-teachers", async () => {
  try {
    const response = await get("/teacher/get-all");
    return response;
  } catch (error) {
    throw error;
  }
});

const TeacherSlice = createSlice({
  name: "tuition-request",
  initialState,
  reducers: {
    // setTeacherModalState(state, action) {
    //   state.teacherModalState = action.payload; // Define the updated state using that type
    //   // state.teacherModalState = TeacherData.find(obj => obj.id.toString() === action.payload)?? undefined;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeachers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTeachers.fulfilled, (state, action) => {
        state.allTeachers = action.payload;

        state.status = "success";
      })
      .addCase(getTeachers.rejected, (state, action) => {
        state.status = "reject";
      });
  },
});

// export the reducer that you can import it and get the value anywhere
export const {} = TeacherSlice.actions;

export default TeacherSlice.reducer;
