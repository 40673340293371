import { configureStore } from "@reduxjs/toolkit";
import TuitionRequestSlice from "./slices/TuitionRequestSlice";
import TeacherSlice from "./slices/TeacherSlice";
import WindowSlice from "./slices/WindowSlice";

export const store = configureStore({
  reducer: {
    tuitionRequest: TuitionRequestSlice,
    teacher: TeacherSlice,
    window: WindowSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
