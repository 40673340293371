import React, { useEffect, useState } from "react";
import "./Student.scss";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { get } from "../../redux/ApiHelper";
import { Student } from "../../interfaces/student.interface";
import { Padding } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: "var(--color-primary)",
    padding:4,
    textAlign:"center"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding:4,
    textAlign:"center"

  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StudentList = () => {
  const [studentList, setStudentList] = useState<Student[]>([]);

  useEffect(() => {
    get("/student/get-all").then((res) => {
      setStudentList(res);
    });
  }, []);

  return (
    <div className="student">
      <div className="title">Student List</div>
      <div className="container">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Profile Picture</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Date of Birth</StyledTableCell>
                <StyledTableCell>Gender</StyledTableCell>
                <StyledTableCell>Institute</StyledTableCell>
                <StyledTableCell>Grade</StyledTableCell>
                <StyledTableCell>Board</StyledTableCell>
                <StyledTableCell>Subjects</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentList.map((student) => (
                <StyledTableRow key={student?._id}>
                  <StyledTableCell>
                    <img
                      src={student?.profilePicture}
                      alt={student?.name}
                      className="profile-img"
                    />
                  </StyledTableCell>
                  <StyledTableCell>{student?.name}</StyledTableCell>
                  <StyledTableCell>{student?.dob}</StyledTableCell>
                  <StyledTableCell>{student?.gender}</StyledTableCell>
                  <StyledTableCell>{student?.institute}</StyledTableCell>
                  <StyledTableCell>{student?.grade}</StyledTableCell>
                  <StyledTableCell>{student?.board}</StyledTableCell>
                  <StyledTableCell>{student?.subject || "N/A"}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default StudentList;
