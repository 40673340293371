import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  useTheme,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { SidebarMenu } from "./sidebarMenu";

const Sidebar: React.FC = () => {
  const theme = useTheme(); // Access the current theme
  const location = useLocation(); // Get current route location
  const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({});

  const toggleMenu = (menu: string) => {
    setOpenMenus((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
  };

  const isActive = (path: string): boolean => location.pathname === path; // Check if the menu's path matches the current route

  return (
    <div className="sidebar">
    <Drawer
      sx={{
        width: 240,
        flexShrink: 0,
       "&  .MuiListItem-gutters ":{
        transition: "background-color 0.3s",

       },
        "& .MuiDrawer-paper": {
          width: 240,
          boxSizing: "border-box",
          backgroundColor: "#171717",
          borderRight:"1px solid var(--color-primary)",
        },

      }}
      variant="permanent"
      className="drewer"
      open
    >
<List>
  {SidebarMenu.map((item) => (
    <React.Fragment key={item.menu}>
      <ListItem
        component={item.path ? Link : "div"}
        to={item.path || undefined}
        onClick={() => {
          if (item.subMenu) toggleMenu(item.menu);
        }}
        sx={{
          backgroundColor: isActive(item.path || "")
            ? "var(--color-dark-gray)" // Active menu background color
            : "var(--color-dark)", // Default background color
          color: isActive(item.path || "")
            ? "var(--color-primary)" // Active menu text color
            : "var(--color-light-gray)", // Default text color
          "&:hover": {
            backgroundColor: "var(--color-gray)", // Hover background color
            color: "var(--color-primary)", // Hover text color
          },
        }}
      >
        <ListItemText primary={item.menu} />
        {item.subMenu && (
          <>
            {openMenus[item.menu] ? <FaChevronUp /> : <FaChevronDown />}
          </>
        )}
      </ListItem>

      {item.subMenu && (
        <Collapse in={openMenus[item.menu]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.subMenu.map((subItem) => (
              <ListItem
                key={subItem.menu}
                component={Link}
                to={subItem.path}
                sx={{
                  pl: 4,
                  backgroundColor: isActive(subItem.path)
                    ? "var(--color-dark-gray)" // Active sub-menu background color
                    : "transparent", // Default sub-menu background color
                  color: isActive(subItem.path)
                    ? "var(--color-primary)" // Active sub-menu text color
                    : "var(--color-light-gray)", // Default sub-menu text color
                  "&:hover": {
                    backgroundColor: "var(--color-gray)", // Hover background color
                    color: "var(--color-primary)", // Hover text color
                  },
                }}
              >
                <ListItemText primary={subItem.menu} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  ))}
</List>


    </Drawer>
    </div>
  );
};

export default Sidebar;
