import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../components/table/TableStyle";
import { useDispatch, useSelector } from "react-redux";
import { TuitionRequestInterface } from "../../../interfaces/tuitionRequest.interface";
import { TeacherInterface } from "../../../interfaces/teacher.interface";
import { assignTeacher } from "../../../redux/slices/TuitionRequestSlice";
import { DispatchType } from "../../../interfaces/redux.interface";
import { getTeachers } from "../../../redux/slices/TeacherSlice";
import Autocomplete from "@mui/material/Autocomplete";
import { startLoading, stopLoading } from "../../../redux/slices/WindowSlice";

const GeneralRequest = () => {
  const dispatch: DispatchType = useDispatch();
  const {
    generalTuitionRequests,
  }: { generalTuitionRequests: TuitionRequestInterface[] } = useSelector(
    (state: any) => state.tuitionRequest
  );

  const { allTeachers }: { allTeachers: TeacherInterface[] } = useSelector(
    (state: any) => state.teacher
  );

  const [selectedTime, setSelectedTime] = useState("");
  const [selectedTeacher, setSelectedTeacher] =
    useState<TeacherInterface | null>(null);

  useEffect(() => {
    dispatch(startLoading());
    dispatch(getTeachers()).finally(() => {
      dispatch(stopLoading());
    });
  }, []);

  const handleAssignTeacher = async (id: string) => {
    if (!selectedTeacher || !selectedTime) {
      alert("Please select a teacher and preferred time.");
      return;
    }
    // Dispatch the assign teacher action
    startLoading();
    await dispatch(
      assignTeacher({
        id,
        teacherId: selectedTeacher._id,
        preferredTimesOfDay: selectedTime,
      })
    ).finally(() => {
      dispatch(stopLoading());
    });
  };

  return (
    <Table sx={{ minWidth: 700 }} aria-label="general requests table">
      <TableHead>
        <TableRow>
          <StyledTableCell>Email</StyledTableCell>
          <StyledTableCell>Phone Number</StyledTableCell>
          <StyledTableCell>Preferred Days</StyledTableCell>
          <StyledTableCell>Preferred Times</StyledTableCell>
          <StyledTableCell>Preferred Subjects</StyledTableCell>
          <StyledTableCell>Assign Teacher</StyledTableCell>
          <StyledTableCell>Status</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {generalTuitionRequests?.map((card, index) => (
          <StyledTableRow key={index}>
            <StyledTableCell>{card.email}</StyledTableCell>
            <StyledTableCell>{card.phoneNumber}</StyledTableCell>
            <StyledTableCell>
              {card.preferredDaysOfWeek.join(", ")}
            </StyledTableCell>
            <StyledTableCell>
              <select
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
                style={{
                  padding: "4px",
                  fontSize: "14px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              >
                <option value="" disabled>
                  Select Time
                </option>
                {card?.preferredTimesOfDay?.map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </StyledTableCell>
            <StyledTableCell>
              {card.preferredSubjects.join(", ")}
            </StyledTableCell>
            <StyledTableCell>
              <Autocomplete
                options={allTeachers}
                getOptionLabel={(option) =>
                  `${option.name} (${option.phoneNumber})`
                }
                onChange={(_, value) => setSelectedTeacher(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Teacher"
                    variant="outlined"
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {},
                        "&:hover fieldset": {},
                        "&.Mui-focused fieldset": {
                          borderColor: "var(--color-primary)", // Focused border color
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "var(--color-dark)", // Label color
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "var(--color-dark)", // Focused label color
                      },
                    }}
                  />
                )}
                sx={{
                  minWidth: 200,
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <select
                value={card.status}
                onChange={() => handleAssignTeacher(card?._id)}
                style={{
                  padding: "4px",
                  fontSize: "14px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              >
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default GeneralRequest;
